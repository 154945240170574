<main-header></main-header>

<section class="py-5">
  <div class="container">
    @if (property !== undefined) {
    <div class="row">
      <div class="col-lg-6">
        <h2 class="fs-4 section-title opacity-75">{{property?.municipality}}, {{property?.state}}</h2>
        <h1 class="fs-2 section-title">{{property?.title}}</h1>
        <p class="d-flex align-items-center gap-1 text-muted opacity-75 mb-2">
          <i class="bi bi-geo-alt-fill fs-4"></i> <span>{{property?.metaGoogleMaps.formatted_address}}</span>
        </p>

        <p class="d-flex align-items-center gap-1 mb-4">
          <span class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-primary">Folio:
            {{property?.foil}}</span>
          <span
            class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-success">{{property?.type}}</span>
          <span
            class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-secondary">{{property?.status}}</span>
        </p>

        <p class="fs-1 fw-semibold section-title text-primary mb-2">
          <span>MXN </span> <span>{{property.price | currency: 'MXN'}}</span>
        </p>

        <div class="mb-4">
          <p-galleria [value]="images" [showItemNavigators]="true" [showThumbnails]="false"
            [responsiveOptions]="responsiveGalleryOptions" [circular]="true" [numVisible]="5"
            [containerStyle]="{ 'max-width': '100%' }">
            <ng-template pTemplate="item" let-item>
              <img [src]="item.itemImageSrc" style="width: 100%; display: block;" />
            </ng-template>
          </p-galleria>
        </div>

        <div class="mt-4">
          <div ngbAccordion>
            <div ngbAccordionItem [collapsed]="false" class="mb-2">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton class="rounded-3">
                  Descripción general
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div [innerHTML]="property?.description"></div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div ngbAccordionItem [collapsed]="false" class="mb-2">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton class="rounded-3">
                  Detalles del inmueble
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="d-flex flex-wrap gap-5">
                      <div class="d-flex gap-3">
                        <div class="d-flex flex-column justify-content-center">
                          <svg height="35px" width="35px" stroke="#5c69ff" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                d="M20.5605 6.75L23.0305 4.28C23.1002 4.21044 23.1555 4.12782 23.1932 4.03688C23.2309 3.94593 23.2503 3.84845 23.2503 3.75C23.2503 3.65155 23.2309 3.55407 23.1932 3.46312C23.1555 3.37218 23.1002 3.28956 23.0305 3.22L20.6709 0.860352">
                              </path>
                              <path
                                d="M3.75 23.25V5.25C3.75 4.85218 3.90804 4.47064 4.18934 4.18934C4.47064 3.90804 4.85218 3.75 5.25 3.75C5.25 3.75 16.7311 3.75 23 3.75">
                              </path>
                              <path
                                d="M0.75 20.561L3.22 23.03C3.28965 23.0997 3.37235 23.155 3.46338 23.1927C3.5544 23.2304 3.65197 23.2498 3.7505 23.2498C3.84903 23.2498 3.9466 23.2304 4.03762 23.1927C4.12865 23.155 4.21135 23.0997 4.281 23.03L6.75 20.56">
                              </path>
                              <path
                                d="M21.75 23.25C22.1478 23.25 22.5294 23.092 22.8107 22.8107C23.092 22.5294 23.25 22.1478 23.25 21.75">
                              </path>
                              <path d="M18.75 23.25H17.25"> </path>
                              <path d="M14.25 23.25H12.75"> </path>
                              <path d="M9.75 23.25H8.25"> </path>
                              <path d="M23.25 18.75V17.25"> </path>
                              <path d="M23.25 14.25V12.75"> </path>
                              <path d="M23.25 9.75V8.25"> </path>
                            </g>
                          </svg>
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fs-6 text-muted m-0">Área</p>
                          <p class="m-0">{{property?.area}} m2</p>
                        </div>
                      </div>
                      <div class="d-flex gap-4">
                        <div class="d-flex flex-column justify-content-center">
                          <svg height="35px" width="35px" fill="#5c69ff" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 482.924 482.924" xml:space="preserve">
                            <g>
                              <path
                                d="M54.979,180.626c0-25.204,20.505-45.71,45.71-45.71h102.608c15.933,0,29.981,8.2,38.165,20.595
                          		c8.185-12.396,22.232-20.595,38.165-20.595h102.609c25.204,0,45.709,20.506,45.709,45.71v2.105h11.443v-44.574
                          		c0-27.66-22.503-50.163-50.163-50.163H93.698c-27.66,0-50.163,22.503-50.163,50.163v44.574h11.443V180.626z" />
                              <path d="M463.712,204.076H19.211C8.618,204.076,0,212.694,0,223.287v111.524c0,10.593,8.618,19.211,19.211,19.211h7.997
                          		l-9.449,26.668c-1.969,5.556,0.94,11.656,6.495,13.624c1.179,0.417,2.382,0.616,3.565,0.616c4.393,0,8.509-2.734,10.06-7.112
                          		l11.975-33.797h383.216l11.975,33.797c1.551,4.378,5.666,7.112,10.06,7.112c1.183,0,2.386-0.199,3.565-0.616
                          		c5.556-1.969,8.464-8.069,6.495-13.624l-9.449-26.668h7.997c10.593,0,19.211-8.618,19.211-19.211V223.287
                          		C482.924,212.694,474.306,204.076,463.712,204.076z" />
                              <path d="M279.627,156.262c-13.434,0-24.364,10.929-24.364,24.363v2.105h151.336v-2.105c0-13.434-10.929-24.363-24.363-24.363
                          		H279.627z" />
                              <path d="M100.689,156.262c-13.435,0-24.364,10.93-24.364,24.364v2.104h151.336v-2.105c0-13.434-10.929-24.363-24.364-24.363
                          		H100.689z" />
                            </g>
                          </svg>
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fs-6 text-muted m-0">Recámaras</p>
                          <p class="m-0">{{property?.room}}</p>
                        </div>
                      </div>
                      <div class="d-flex gap-4">
                        <div class="d-flex flex-column justify-content-center">
                          <svg height="35px" width="35px" fill="#5c69ff" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 493.05 493.05" xml:space="preserve">
                            <path d="M472.958,290.74h-15.796V118.805c0-40.106-32.629-72.735-72.737-72.735c-37.391,0-68.27,28.362-72.283,64.7h-49.827
                          	c-10.418,0-18.864,8.445-18.864,18.862c0,10.418,8.445,18.865,18.864,18.865h117.533c10.419,0,18.864-8.447,18.864-18.865
                          	c0-10.417-8.445-18.862-18.864-18.862H332.3c3.881-25.278,25.778-44.7,52.125-44.7c29.079,0,52.737,23.657,52.737,52.735V290.74
                          	H20.092C8.996,290.74,0,299.734,0,310.831c0,11.097,8.996,20.093,20.092,20.093h5.035c2.475,18.646,9.083,67.859,12.679,89.093
                          	c2.486,14.683,12.072,26.964,26.964,26.964h363.511c14.892,0,24.628-12.258,26.964-26.964c3.374-21.233,10.116-70.446,12.652-89.093
                          	h5.061c11.096,0,20.092-8.996,20.092-20.093C493.05,299.734,484.054,290.74,472.958,290.74z" />
                          </svg>
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fs-6 text-muted m-0">Baños</p>
                          <p class="m-0">{{property?.bath}}</p>
                        </div>
                      </div>
                      <div class="d-flex gap-4">
                        <div class="d-flex flex-column justify-content-center">
                          <svg height="35px" width="35px" fill="#5c69ff" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512 512" xml:space="preserve">
                            <g>
                              <path
                                d="M423.887,113.03V30.522C423.887,13.669,410.218,0,393.366,0H66.284C49.431,0,35.766,13.669,35.766,30.522
                          		v82.508c0,16.853,13.666,30.522,30.518,30.522h327.082C410.218,143.552,423.887,129.884,423.887,113.03z" />
                              <path d="M191.879,472.286c0,21.937,17.784,39.714,39.714,39.714h2.651c21.93,0,39.711-17.777,39.711-39.714V329.267
                          		h-82.076V472.286z" />
                              <path
                                d="M445.472,58.738v47.992v31.933c0,9.02-5.498,17.161-13.887,20.533l-0.473,0.188l-180.311,86.67l0.018,0.038
                          		c-19.5,8.284-32.26,27.374-32.26,48.636v16.59h30.762v-16.59c0-9.042,5.493-17.169,13.879-20.54l0.48-0.196l180.304-86.662
                          		l-0.022-0.038c19.52-8.284,32.272-27.375,32.272-48.629V106.73C476.226,85.422,463.616,67.12,445.472,58.738z" />
                            </g>
                          </svg>
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fs-6 text-muted m-0">Remodelado</p>
                          <p class="m-0">{{(property?.remodeled === 1)?'Si':'No'}}</p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <a href="https://api.whatsapp.com/send?phone=5219983058167&text=%C2%A1Hola!%20Necesito%20el%20apoyo%20de%20Solviiamigo."
            target="_blank" class="btn btn-success rounded-3 w-100 py-3">
            <i class="bi bi-whatsapp lh-1 fs-4 align-bottom me-1"></i> ¡Me interesa!
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-block w-100 h-100">
          <google-map width="100%" height="100%" [options]="{ disableDefaultUI: true, zoomControl: false}"
            [center]="{lat: this.property?.latitude, lng: this.property?.longitude}" [zoom]="17"
            [mapId]="'2558f9905a1514c1'">
            <map-marker [position]="property?.metaGoogleMaps.geometry.location" />
            <map-circle [center]="property?.metaGoogleMaps.geometry.location" [radius]="200" />
          </google-map>
        </div>
      </div>
    </div>
    }
  </div>
</section>

<section>
  <div class="container">
    <need-help></need-help>
  </div>
</section>

<main-footer></main-footer>
