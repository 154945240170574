<div class="text-bg-primary text-light rounded-3 p-5">
  <div class="row">
    <div class="col-lg-6 d-flex flex-column justify-content-center mb-3">
      <p class="fs-5 mb-2 opacity-50 text-uppercase">Lineas de servicio</p>
      <h3 class="fs-1 mb-4 section-title">¿Necesitas ayuda?</h3>
      <p class="fs-5 fw-light m-0">Nuestro Solviiamigo está listo para atenderte.</p>
    </div>
    <div class="col-lg-6 d-flex flex-column justify-content-center">
      <p class="fw-medium m-0">Horario de atención</p>
      <p class="fw-medium">Lunes a Sábado de 9:00 am a 7:00 pm</p>
      <p class="d-flex align-items-center m-0 gap-3">
        <i class="bi bi-envelope-at fs-4"></i> <span>{{'info@solviing.com'}}</span>
      </p>
      <p class="d-flex align-items-center m-0 gap-3">
        <i class="bi bi-whatsapp fs-4"></i> <span>+(551) 738-6237</span>
      </p>

      <!-- <div class="mt-2">
        <a href="https://api.whatsapp.com/send?phone=5219999999999&text=%C2%A1Hola!%20Necesito%20el%20apoyo%20de%20Solviiamigo."
          target="_blank" class="btn btn-outline-light rounded-5 px-4 py-2">
          <i class="bi bi-wechat lh-1 fs-4 align-bottom me-1"></i> Solviiamigo te responde
        </a>
      </div> -->

      <ul class="list-unstyled d-flex gap-2 m-0 mt-3">
        <li>
          <a class="d-block fs-5 p-3 lh-1 rounded-circle bg-light" href="https://facebook.com" target="_blank">
            <i class="bi bi-facebook"></i>
          </a>
        </li>
        <li>
          <a class="d-block fs-5 p-3 lh-1 rounded-circle bg-light" href="https://instagram.com" target="_blank">
            <i class="bi bi-instagram"></i>
          </a>
        </li>
        <!-- <li>
          <a class="d-block fs-5 p-3 lh-1 rounded-circle bg-light" href="https://tiktok.com" target="_blank">
            <i class="bi bi-tiktok"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</div>